import { Wrapper } from 'components/wrapper'
import { useState } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { Waypoint } from 'react-waypoint'
import InclusionImage from '../../assets/images/inclusion.webp'
import Technology from '../../assets/images/technology.webp'
import VisionImage from '../../assets/images/vision.webp'
import * as S from './styles'

export function SectionThree() {
  const [selectedTab, setSelectdTab] = useState('')

  return (
    <S.Container id="QuemSomos">
      <S.Divider>
        <S.Header>
          <S.HeaderItem
            href="#QuemSomos"
            select={selectedTab === 'QuemSomos'}
            onClick={() => setSelectdTab('QuemSomos')}
          >
            Quem somos
          </S.HeaderItem>
          <S.HeaderItem
            select={selectedTab === 'NossaVisao'}
            href="#NossaVisao"
            onClick={() => setSelectdTab('NossaVisao')}
          >
            Nossa visão
          </S.HeaderItem>
          <S.HeaderItem
            select={selectedTab === 'OqueFazemos'}
            href="#OqueFazemos"
            onClick={() => setSelectdTab('OqueFazemos')}
          >
            O que fazemos
          </S.HeaderItem>
        </S.Header>
        <Waypoint
          onEnter={() => setSelectdTab('QuemSomos')}
          onLeave={() => setSelectdTab('')}
        />
      </S.Divider>

      <Wrapper>
        <S.Grid>
          <S.ContainerText data-anime="left">
            <S.Title>SOMOS CRIADORES</S.Title>
            <S.TextOne>
              Criamos inclusão financeira através de soluções inovadoras,
              eficazes e sem burocracias.
            </S.TextOne>

            <S.TextTwo>
              Desde 2016 estamos construindo uma comunidade de brasileiros que
              contam com uma plataforma de crédito inteligente para encarar seus
              diversos desafios financeiros
            </S.TextTwo>
          </S.ContainerText>

          <S.Logo src={InclusionImage} alt="inclusion" data-anime="right" />
        </S.Grid>

        <S.Grid direction="row-reverse" id="NossaVisao">
          <S.ContainerText data-anime="right">
            <S.TextOne>
              Re-imaginamos a jornada do crédito para além das financeiras
            </S.TextOne>

            <S.TextTwo>
              Quando você faz uma venda com a NoVerde o cliente paga parcelado,
              mas você recebe à vista. Além de oferecer um meio de pagamento
              adicional, oferecemos financiamento rápido e inteligente, com base
              em Dados, utilizando informações não convencionais. Tudo isso em
              uma experiência digital e segura.
            </S.TextTwo>
          </S.ContainerText>

          <S.Logo src={VisionImage} alt="VisionImage" data-anime="left" />
        </S.Grid>
        <Waypoint onEnter={() => setSelectdTab('NossaVisao')} />

        <S.Grid id="OqueFazemos">
          <S.ContainerText data-anime="left">
            <S.Title>O QUE FAZEMOS </S.Title>

            <S.TextOne>
              Criar experiências de crédito memoráveis e integradas em jornadas
              não-financeiras
            </S.TextOne>
            <S.WrapperList>
              <S.List>
                <S.Icon component={<FaCheckCircle />} />
                <S.ListItem>
                  Ofertas de empréstimo pessoal que podem ser disponibilizadas
                  em seu marketplace ou wallet
                </S.ListItem>
              </S.List>

              <S.List>
                <S.Icon component={<FaCheckCircle />} />
                <S.ListItem>
                  Financiamento como opção de pagamento no checkout da sua loja
                  ou ecommerce
                </S.ListItem>
              </S.List>

              <S.List>
                <S.Icon component={<FaCheckCircle />} />
                <S.ListItem>
                  Análise de Crédito e aprovação real time (24×7)
                </S.ListItem>
              </S.List>

              <S.List>
                <S.Icon component={<FaCheckCircle />} />
                <S.ListItem>
                  Tudo isso via Portal de Simulações ou integração via API
                  gateway.
                </S.ListItem>
              </S.List>
            </S.WrapperList>
          </S.ContainerText>

          <S.Logo src={Technology} alt="technology" data-anime="right" />
        </S.Grid>
        <Waypoint onEnter={() => setSelectdTab('OqueFazemos')} />
      </Wrapper>
    </S.Container>
  )
}
