import { HeaderMenuTitle } from 'components/headerTitle'
import { Logo } from 'components/logo'
import Hamburger from 'hamburger-react'
import { useState } from 'react'
import { Collapse } from 'react-collapse'
import useWindowDimensions from '../../hooks/useDimensions'
import { Button } from '../button'
import './collapseStyles.css'
import * as S from './styles'

type HeaderProps = {
  toggleMenu: () => void
}

export function Header({ toggleMenu }: HeaderProps) {
  const [openMenu, setOpenMenu] = useState(false)
  const { hidelg, hideIPadPro } = useWindowDimensions()

  return (
    <S.Wrapper>
      <S.Header>
        <a href="https://www.noverde.com.br/">
          <Logo />
        </a>
        {hideIPadPro && <Hamburger toggle={setOpenMenu} toggled={openMenu} />}
        {!hidelg && <Hamburger toggle={setOpenMenu} toggled={openMenu} />}

        {hidelg && !hideIPadPro && (
          <S.HeaderMenu>
            <HeaderMenuTitle title="Sobre" link="#QuemSomos" />
            <HeaderMenuTitle
              title="Soluções de Crédito"
              link="#SolucoesDeCredito"
            />
            <HeaderMenuTitle title="Parceiro" link="#Parceiro" />
            <HeaderMenuTitle
              title="Para o consumidor"
              link={'https://www.noverde.com.br/'}
            />
            <HeaderMenuTitle
              title="Portal de financiamentos"
              link={'https://portal.noverde.com.br'}
            />

            <Button title="Comece Agora" onClick={() => toggleMenu()} />
          </S.HeaderMenu>
        )}
      </S.Header>

      {openMenu && (
        <Collapse
          theme={{ collapse: 'Collapse' }}
          isOpened={openMenu}
          initialStyle={{ height: 0, overflow: 'hidden' }}
        >
          <S.HeaderMenu open={openMenu}>
            <HeaderMenuTitle title="Sobre" link="#QuemSomos" />
            <HeaderMenuTitle
              title="Soluções de Crédito"
              link="#SolucoesDeCredito"
            />
            <HeaderMenuTitle title="Parceiro" link="#Parceiro" />
            <HeaderMenuTitle
              title="Para o consumidor"
              link={'https://www.noverde.com.br/'}
            />
            <HeaderMenuTitle
              title="Portal de financiamentos"
              link={'https://portal.noverde.com.br'}
            />

            <Button
              title="Comece Agora"
              open={openMenu}
              onClick={() => {
                toggleMenu()
                setOpenMenu(!openMenu)
              }}
            />
          </S.HeaderMenu>
        </Collapse>
      )}
    </S.Wrapper>
  )
}
