import { HTMLAttributes } from 'react'
import * as S from './styles'

export type TextareaProps = {
  height?: string
  width?: string
  value?: string
  name?: string
  useBackground?: boolean
} & HTMLAttributes<HTMLTextAreaElement>

export function Textarea({ ...props }: TextareaProps) {
  return <S.Textarea {...props} />
}
