import { cloneElement } from 'react'
import styled, { css } from 'styled-components'
import PdfImage from '../../assets/images/pdfIcon.webp'
import { devices } from '../../layout/devices'

export const Grid = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    background-color: ${theme.colors.white};
    box-shadow: 0 15px 35px ${theme.colors.greyborders};
    border-radius: 10px;
    gap: 40px;
    max-width: 1200px;
    min-height: 500px;
    margin: 0 auto;
    z-index: 2;
    position: relative;

    @media ${devices.mobile} {
      flex-wrap: wrap;
      max-height: 1000px;
      margin: 1rem;
    }
  `}
`

export const Image = styled.div`
  width: 50%;
  background-image: url(${PdfImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: auto;

  @media ${devices.mobile} {
    min-height: 300px;
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`
export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 2.2rem;
    color: ${theme.colors.black};
    font-weight: ${theme.font.weight[800]};

    & > strong {
      color: ${theme.colors.noverde};
      font-weight: ${theme.font.weight[800]};
    }

    & > p {
      font-size: 2.2rem;
      color: ${theme.colors.black};
      font-weight: ${theme.font.weight[800]};
    }
  `}
`

export const SubTitle = styled.h6`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: 1.25rem;
    font-weight: ${theme.font.weight[800]};
    margin-left: 30px;
    max-width: 350px;

    @media ${devices.mobileMedium} {
      font-size: 1.2rem;
    }
  `}
`

export const WrapperIcon = styled.div`
  display: -webkit-inline-box;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 50px 50px 100px 70px;

  @media ${devices.mobileMedium} {
    padding: 1rem;
    flex-wrap: wrap;
    margin: 0 20px 0 0;
  }
`

export const Icon = styled(({ component, ...props }) =>
  cloneElement(component, props)
)`
  ${({ theme }) => css`
    color: ${theme.colors.iconcard};
    height: 2rem;
    width: 2rem;
  `}
`

export const ContainerIcon = styled.a`
  ${({ theme }) => css`
    position: absolute;
    height: 80px;
    width: 80px;
    border-radius: 100%;
    border: 5px solid ${theme.colors.white};
    top: 40%;
    left: 40%;
    cursor: pointer;
    background: ${theme.colors.white};
    transition: ${theme.transition.default};

    :hover {
      border: 5px solid ${theme.colors.white};
      background: transparent;

      & > svg {
        color: ${theme.colors.white};
      }
    }
  `}
`

export const PdfIcon = styled(({ component, ...props }) =>
  cloneElement(component, props)
)`
  ${({ theme }) => css`
    color: ${theme.colors.noverde};
    height: 2rem;
    width: 2rem;
    position: relative;
    top: 25%;
    left: 25%;
    cursor: 'pointer';
    transition: ${theme.transition.default};
  `}
`
