import { BusinessTheme } from 'layout/theme'
import React from 'react'
import { ThemeProvider } from 'styled-components'

interface BusinessThemeProviderProps {
  children?: React.ReactNode
}

export const BusinessThemeProvider = ({
  children
}: BusinessThemeProviderProps) => {
  return <ThemeProvider theme={BusinessTheme}>{children}</ThemeProvider>
}
