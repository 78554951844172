import styled from 'styled-components'
import { devices } from '../../layout/devices'

export const HeaderLogo = styled.img`
  width: 10rem;
  height: auto;

  @media ${devices.small} {
    width: 12rem;
  }
`
