import { FiSettings, FiShare2, FiSlack, FiThumbsUp } from 'react-icons/fi'
import * as S from './styles'

export function Card() {
  return (
    <S.CardWrapper>
      <S.Container className="down">
        <S.Card data-anime="top">
          <S.Icon component={<FiThumbsUp />}></S.Icon>

          <S.Title>Empréstimo</S.Title>
          <S.Body>
            Ofereça crédito como serviço financeiro no seu estabelecimento.
          </S.Body>
        </S.Card>

        <S.Card data-anime="top">
          <S.Icon component={<FiSlack />}></S.Icon>

          <S.Title>Customização</S.Title>

          <S.Body>Plataforma personalizada com a sua marca.</S.Body>
        </S.Card>
      </S.Container>

      <S.Container>
        <S.Card data-anime="top">
          <S.Icon component={<FiShare2 />} />
          <S.Title>Financiamento</S.Title>

          <S.Body>
            Financiamos diversos setores, como Saúde/Bem Estar, Construção e
            Mobília.
          </S.Body>
        </S.Card>

        <S.Card data-anime="top">
          <S.Icon component={<FiSettings />}></S.Icon>

          <S.Title>API</S.Title>

          <S.Body>API robusta, pronta para uso, segura e eficaz.</S.Body>
        </S.Card>
      </S.Container>
    </S.CardWrapper>
  )
}
