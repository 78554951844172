import { devices } from 'layout/devices'
import { cloneElement } from 'react'
import styled, { css } from 'styled-components'

type Props = {
  open: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  height: 100%;
`

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 1.5rem;
    font-weight: ${theme.font.weight[800]};
    width: 100%;
  `}
`
export const SubTitle = styled.p`
  ${({ theme }) => css`
    font-size: 1rem;
    color: ${theme.colors.text};
  `}
`

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.noverde}30;
    padding-bottom: 1rem;
    gap: 20px;
  `}
`

export const Icon = styled(({ component, ...props }) =>
  cloneElement(component, props)
)`
  ${({ theme }) => css`
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    &:hover {
      color: ${theme.colors.noverde};
      transition: all 0.3s;
    }
  `}
`

export const MenuContainer = styled.nav<Props>`
  ${({ open, theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: ${theme.colors.graybackground};
    height: 100%;
    text-align: left;
    padding: 0 2rem;
    position: relative;
    top: 0;
    left: 0;
    transition: width 1s;
    width: ${open ? '50rem' : '0%'};
    overflow: ${open ? 'auto' : 'hidden'};
    z-index: 1;
  `}
`
export const CustomForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media ${devices.mobileMedium} {
    gap: 20px;
  }
`

export const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media ${devices.mobileMedium} {
    gap: 20px;
  }
`
export const SubTitleForm = styled.h1`
  ${({ theme }) => css`
    font-size: 1rem;
    font-weight: ${theme.font.weight[600]};
    width: 100%;
  `}
`
