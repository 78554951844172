import { cloneElement } from 'react'
import styled, { css } from 'styled-components'
import { ButtonFabProps } from '.'

export const Fab = styled.a<ButtonFabProps>`
  ${({ theme, showFab }) => css`
    position: fixed;
    z-index: 100;
    bottom: 30px;
    right: ${showFab ? '30px' : '-50px'};
    text-decoration: none;
    background-color: ${theme.colors.inputcolor};
    font-size: 14px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    width: 50px;
    height: 50px;
    cursor: pointer;
    text-align: center;
    line-height: 51px;
    border-radius: 50%;
    -webkit-transition: all 250ms ease-in-out;
    -moz-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      margin-top: 10px;
    }
  `}
`

export const Icon = styled(({ component, ...props }) =>
  cloneElement(component, props)
)`
  ${({ theme }) => css`
    color: ${theme.colors.noverde};
    height: 25px;
    width: 25px;
  `}
`
