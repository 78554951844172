import styled, { css } from 'styled-components'
import Background from '../../assets/images/background.svg'
import { devices } from '../../layout/devices'

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: 3rem;
    font-weight: ${theme.font.weight[800]};

    & > strong {
      color: ${theme.colors.noverde};
      font-weight: ${theme.font.weight[800]};
      font-size: 3rem;
    }
  `}
`

export const Chip = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.noverde};
    border-radius: 30px;
    width: 240px;
    height: 30px;
    text-align: center;
    padding: 0.25rem;
    line-height: 1.3;
    font-weight: ${theme.font.weight[400]};
    position: relative;

    &:after {
      display: block;
      content: '';
      position: absolute;
      border-radius: 30px;
      background: ${theme.colors.noverde};
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.15;
      z-index: 0;
    }
  `}
`

export const Body = styled.p`
  ${({ theme }) => css`
    font-size: 1.25rem;
    color: ${theme.colors.textblue};
  `}
`

export const WrapperInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 20px;
  align-items: center;

  @media ${devices.medium} {
    margin-top: 200px;
    align-items: baseline;
    min-width: 550px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  @media ${devices.medium} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`
export const BackGround = styled.div`
  @media ${devices.large} {
    background-image: url(${Background});
    background-position: top;
    max-width: 1100px;
  }
`
export const WrapperButton = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media ${devices.mobileMedium} {
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const Logo = styled.img`
  max-width: 770px;
  max-height: 859px;
`
