import React, { ReactNode } from 'react'
import './styles.css'

type CardProps = {
  children: ReactNode
}

export function PartnerCard({ children }: CardProps) {
  return (
    <div className="card_container" data-anime="top">
      <div className="card_wrapper">{children}</div>
    </div>
  )
}
