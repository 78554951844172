import styled, { css } from 'styled-components'
import { TextareaProps } from '.'

export const Textarea = styled.textarea<TextareaProps>`
  ${({ theme, height, width, useBackground }) => css`
    border: 1px solid ${theme.colors.greyborders};
    border-radius: 24px;
    color: ${theme.colors.text};
    font-size: 1rem;
    font-family: 'Open Sans';
    font-weight: 300;
    min-height: 120px;
    outline: none;
    padding: 1rem;
    resize: none;
    transition: ease-in-out 0.3s;
    width: ${width ?? '100%'};

    ${useBackground && `background-color:${theme.colors.inputcolor}`}

    ${!!height && `height: ${height}`};

    :focus {
      border: 1px solid ${theme.colors.placeholders};
    }

    ::placeholder {
      color: ${theme.colors.black};
    }
  `}
`
