import { Wrapper } from 'components/wrapper'
import { useState } from 'react'
import useWindowDimensions from '../../hooks/useDimensions'
import { validateForm } from '../../utils/validationForm'
import { CustomCollapseCard } from './customCollapseCard'
import { Form } from './form'
import * as S from './styles'

export function SectionFive() {
  const { hidelg } = useWindowDimensions()
  const [success, setSuccess] = useState(false)
  const hideForm = () => {
    const form = document.getElementById('partner-form')
    const inputs = ['name', 'email', 'tel', 'company_name']

    if (form && validateForm({ inputs })) {
      form.style.display = 'none'
      setSuccess(true)
    }
  }

  return (
    <S.Container>
      <Wrapper>
        <S.Title>Temos a melhor solução de crédito para seu cliente</S.Title>

        <S.SubTitle data-anime="down">
          Viabilizamos oportunidades para que milhares de brasileiros realizem
          seus sonhos. Cadastro rápido, simulação e proposta em menos de 15
          minutos.
        </S.SubTitle>

        <S.Grid>
          {hidelg && (
            <S.GridCollapse>
              <CustomCollapseCard
                title="Porque oferecer soluções de crédito aos meus clientes?"
                infos="  O cliente pode parcelar o pagamento de um produto/ serviço – sem
            a necessidade de um cartão de crédito – ou solicitar um
            empréstimo pessoal para usar da forma que quiser. Você aumenta
            suas vendas, fomenta sua recorrência, monetiza o seu negócio e
            ainda recebe as vendas parceladas à vista."
              />

              <CustomCollapseCard
                title="Como começar?"
                infos="Para começar, preencha o formulário de interesse clicando aqui. Caso o perfil do seu negócio se encaixe com as nossas soluções disponíveis, iremos entrar em contato para direcionar a melhor alternativa de crédito para você oferecer a seus clientes."
              />

              <CustomCollapseCard
                title="Como funciona a integração? O que eu preciso fazer?"
                infos={
                  <S.WrapperContainerText>
                    <div>
                      <strong>Financiamento: </strong>
                      Solução de ponta a ponta, desde a análise de crédito até a
                      gestão da cobrança, incluindo preparação do seu time para
                      usar nosso Portal de Financiamento. Você só precisa focar
                      no seu produto e ver suas vendas aumentarem!
                    </div>
                    <div>
                      <strong> Empréstimo Pessoal: </strong>
                      Desenhamos um processo fácil e rápido para você se
                      integrar a nossa solução, clique aqui e acesse a nossa
                      documentação pública. Após a aquisição do empréstimo, a
                      Noverde atuará como plataforma de pagamento das parcelas e
                      gestão de cobrança dos negócios efetivados.
                    </div>
                  </S.WrapperContainerText>
                }
              />

              <CustomCollapseCard
                title="Há restrições de produtos ou ticket médio que podem ser financiados? Quais as taxas de juros praticadas?"
                infos="A NoVerde entende o perfil do seu cliente e as característica do negócio para propor a oferta adequada para cada tipo de cliente/parceiro."
              />

              <CustomCollapseCard
                title="Como funciona para o meu cliente?"
                infos={
                  <>
                    A jornada da contratação do financiamento e empréstimo é
                    feita por meio do canal da sua empresa (integrado na sua
                    interface ou via portal de financiamento). Após a aquisição
                    do crédito, toda a gestão de pagamentos e cobrança está
                    disponível na{' '}
                    <S.Link
                      href="https://app.noverde.com.br/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      plataforma NoVerde.
                    </S.Link>
                  </>
                }
              />

              <CustomCollapseCard
                title="Como meu cliente faz para pagar as parcelas?"
                infos="Os pagamentos podem ser feitos em qualquer momento direto pela área logada da plataforma NoVerde, via site ou app, pelos meios de sua preferência(PIX, boleto, Cartão de crédito). Na área logada, seu cliente acompanha todo o status atualizado de seu contrato. Link do ambiente logado do cliente."
              />
            </S.GridCollapse>
          )}

          <S.ContainerForm>
            <S.FormTitle> Você quer mais para sua empresa?</S.FormTitle>
            <S.FormSubtitle>
              Preencha este formulário que entraremos em contato. Nós nos
              esforçamos para responder a todas as perguntas dentro de 24 horas
              nos dias úteis.
            </S.FormSubtitle>

            <Form hideForm={hideForm} />

            {success && (
              <S.Success>
                Dados enviados com sucesso. Nossa Equipe de vendas estrará em
                contato o mais rápido possível.
              </S.Success>
            )}
          </S.ContainerForm>
        </S.Grid>
      </Wrapper>
    </S.Container>
  )
}
