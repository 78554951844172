import styled, { css } from 'styled-components'

export const Ball = styled.div`
  ${({ theme }) => css`
    width: 3px;
    height: 3px;
    background-color: ${theme.colors.noverde};
    border-radius: 100%;
  `}
`

export const GridDots = styled.div`
  display: flex;
  gap: 15px;
`

type DotsProps = {
  useTop?: string
  useLeft?: string
  useRight?: string
}

export const Container = styled.div<DotsProps>`
  ${({ useTop, useLeft, useRight }) => css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 0;
    position: absolute;

    ${useTop && `top: ${useTop}`};
    ${useLeft && `left: ${useLeft}`};
    ${useRight && `right: ${useRight}`};
  `}
`
