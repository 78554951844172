import React from 'react'
import { BusinessTheme } from '../../layout/theme'
import * as S from './styles'

export function Divider() {
  return (
    <S.Divider>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill={BusinessTheme.colors.white}
        viewBox="0 0 1280 140"
        preserveAspectRatio="none"
        height="100px"
        width="100%"
      >
        <path d="M320 28C160 28 80 49 0 70V0h1280v70c-80 21-160 42-320 42-320 0-320-84-640-84z" />
      </svg>
    </S.Divider>
  )
}
