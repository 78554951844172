import * as S from './styles'

type DotsProps = {
  columns: number
  rows: number
  useTop?: string
  useLeft?: string
  useRight?: string
}

export function Dots({ columns, rows, ...rest }: DotsProps) {
  const dotsColumn = Array(columns).fill(0)
  const dotsRows = Array(rows).fill(0)

  return (
    <S.Container {...rest}>
      {dotsColumn.map((dot, index) => (
        <S.GridDots key={`dot_${index}`}>
          {dotsRows.map((row, _index) => (
            <S.Ball key={`line_${_index}`} />
          ))}
        </S.GridDots>
      ))}
    </S.Container>
  )
}
