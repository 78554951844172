import styled, { css } from 'styled-components'

type ButtonProps = {
  open?: boolean
  isFull?: boolean
  size?: string
}

export const Button = styled.a<ButtonProps>`
  ${({ theme, open, isFull, size }) => css`
    align-items: center;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.noverde};
    border-radius: 25px;
    color: ${theme.colors.noverde};
    display: flex;
    font-weight: theme.font-weight[400];
    justify-content: center;
    letter-spacing: 0.16px;
    padding: 10px 34px;
    position: relative;
    font-size: 1rem;
    width: ${!!size && size};
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background: ${theme.colors.noverde};
      color: ${theme.colors.white};
      box-shadow: 0 15px 35px #0cd15b26;
    }

    ${!!isFull &&
    ` background-color: ${theme.colors.noverde};
      color: ${theme.colors.white};
      &:hover {
        background: ${theme.colors.white};
        color: ${theme.colors.noverde};
        box-shadow: 0 15px 35px #0cd15b26;
      }
    `};

    ${!!open && `width: 100%`};
  `}
`
