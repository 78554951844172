import { ButtonForm } from 'components/buttonForm'
import { IframeForm } from 'components/iframeForm'
import { SelectField } from 'components/selectfield'
import { Textarea } from 'components/textarea'
import { TextField } from 'components/textfield'
import * as S from './styles'

type FormProps = {
  hideForm: () => void
}

export function Form({ hideForm }: FormProps) {
  return (
    <>
      <S.CustomForm
        id="partner-form"
        target="my-response-iframe"
        action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfSbP5D8m2qPy1u8btQtvJghcNV7Whh4Sp4v5-iUjaEm2mJ7g/formResponse"
      >
        <TextField
          required
          type="text"
          placeholder="Nome"
          name="entry.1540148450"
          id="name"
        />
        <TextField
          required
          type="email"
          placeholder="Email"
          name="entry.772667696"
          id="email"
        />
        <TextField
          required
          type="tel"
          placeholder="Telefone"
          name="entry.947416374"
          id="tel"
        />
        <TextField
          required
          type="text"
          placeholder="Nome da Empresa"
          name="entry.365491273"
          id="company_name"
        />
        <SelectField />
        <Textarea placeholder="Mensagem" name="entry.607646257" />
        <ButtonForm
          onClick={hideForm}
          title="Enviar"
          size="200px"
          type="submit"
        />
      </S.CustomForm>

      <IframeForm />
    </>
  )
}
