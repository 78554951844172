import { BusinessTheme } from 'layout/theme'
import { ThemeColorsKeys } from './themeColorKeys'

type InstagramIconProps = {
  color?: ThemeColorsKeys
}

export function InstagramIcon({ color = 'blue700' }: InstagramIconProps) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3688 1.22559H7.10666C3.99671 1.22559 1.47559 3.74671 1.47559 6.85666V18.1188C1.47559 21.2288 3.99671 23.7499 7.10666 23.7499H18.3688C21.4788 23.7499 23.9999 21.2288 23.9999 18.1188V6.85666C23.9999 3.74671 21.4788 1.22559 18.3688 1.22559Z"
        stroke={BusinessTheme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.738 16.7113C15.0704 16.7113 16.9613 14.8204 16.9613 12.488C16.9613 10.1555 15.0704 8.26465 12.738 8.26465C10.4055 8.26465 8.51465 10.1555 8.51465 12.488C8.51465 14.8204 10.4055 16.7113 12.738 16.7113Z"
        stroke={BusinessTheme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0732 6.15234V6.15435"
        stroke={BusinessTheme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
