import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import * as S from './styles'

type ButtonProps = {
  title: string
  width?: string
  link: string
}

export function ButtonLink({ link, title, ...rest }: ButtonProps) {
  return (
    <S.Button href={link} {...rest}>
      {title}
      <S.Icon component={<IoIosArrowForward />} />
    </S.Button>
  )
}
