import styled, { css } from 'styled-components'
import { TextFieldProps } from '.'

export const Input = styled.input<TextFieldProps>`
  ${({ theme, height, width, useBackground }) => css`
    border: 1px solid ${theme.colors.greyborders};
    border-radius: 24px;
    color: ${theme.colors.text};
    font-size: 1rem;
    font-weight: 300;
    font-family: 'Open Sans';
    outline: none;
    padding: 1rem;
    transition: ease-in-out 0.3s;
    width: ${width ?? '100%'};

    ${!!height && `height: ${height}`};
    ${!!useBackground && `background: ${theme.colors.inputcolor}`};

    :focus {
      border: 1px solid ${theme.colors.placeholders};
    }

    ::placeholder {
      color: ${theme.colors.black};
    }
  `}
`
