import { MdKeyboardArrowUp } from 'react-icons/md'
import * as S from './styles'

export type ButtonFabProps = {
  showFab: boolean
}

export function ButtonFab({ ...props }: ButtonFabProps) {
  return (
    <S.Fab href="#" {...props}>
      <S.Icon component={<MdKeyboardArrowUp />} />
    </S.Fab>
  )
}
