export const BusinessTheme = {
  colors: {
    acqua: '#00ffb2',
    backgrounds: '#F9FAFD',
    black: '#000000',
    blue10: ' #EFF8FF',
    blue400: '#1890FF',
    blue500: '#2F80ED',
    blue700: '#001E65',
    blue800: '#151B41',
    blue900: '#080a18',
    error: '#FF6464',
    galaxy: '#0c0f25',
    greyborders: '#E2E2E2',
    green10: '#F3FFF9',
    green400: '#27AE60',
    noverde: '#0cd15b',
    orange10: '#FFFBF5',
    placeholders: '#AAABB6',
    red10: '#F7D6D2',
    sunset: '#ff9900',
    text: '#646e7d',
    white: '#ffffff',
    graybackground: '#f7f4fb',
    border: '#ddd',
    textblue: '#445781',
    textgray: '#696969',
    boxshadow: 'rgb(38 42 76 / 30%)',
    inputcolor: '#0CD15B12',
    iconcard: '#0466c8',
    iconbullets: '#AFFC41',
    borderCollapse: '#e7e7e7'
  },
  font: {
    weight: {
      100: 100,
      200: 200,
      300: 300,
      400: 400,
      500: 500,
      600: 600,
      700: 700,
      800: 800,
      900: 900
    }
  },
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  }
}
