import { ButtonForm } from 'components/buttonForm'
import { IframeForm } from 'components/iframeForm'
import { SelectField } from 'components/selectfield'
import { Textarea } from 'components/textarea'
import { TextField } from 'components/textfield'
import { BiXCircle } from 'react-icons/bi'
import { validateForm } from '../../../utils/validationForm'
import * as S from './styles'

type FormProps = {
  toggleSideMenu: () => void
}

export function Form({ toggleSideMenu }: FormProps) {
  const inputs = ['side_name', 'side_email', 'side_tel', 'side_company_name']

  const hideForm = () => {
    const form = document.getElementById('side-partner-form') as HTMLFormElement

    if (form && validateForm({ inputs })) {
      toggleSideMenu()

      setTimeout(() => {
        form.reset()
      }, 3000)
    }
  }

  return (
    <S.Container>
      <S.Header>
        <S.Title>Seja um parceiro</S.Title>

        <S.Icon component={<BiXCircle />} onClick={toggleSideMenu} />
      </S.Header>

      <S.SubTitle>
        Preecha o formulário abaixo, nosso time de vendas entrará em contato.
      </S.SubTitle>

      <S.CustomForm
        id="side-partner-form"
        target="my-response-iframe"
        action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfSbP5D8m2qPy1u8btQtvJghcNV7Whh4Sp4v5-iUjaEm2mJ7g/formResponse"
      >
        <S.WrapperForm>
          <TextField
            type="text"
            placeholder="Nome"
            useBackground
            name="entry.1540148450"
            id={inputs[0]}
            required
          />
          <TextField
            type="email"
            placeholder="Email"
            useBackground
            name="entry.772667696"
            id={inputs[1]}
            required
          />
          <TextField
            type="tel"
            placeholder="Telefone"
            useBackground
            name="entry.947416374:"
            id={inputs[2]}
            required
          />
          <TextField
            type="text"
            placeholder="Nome da Empresa"
            useBackground
            name="entry.365491273"
            id={inputs[3]}
            required
          />
          <SelectField useBackground />
          <Textarea
            placeholder="Mensagem"
            useBackground
            name="entry.607646257"
          />
        </S.WrapperForm>

        <ButtonForm title="Enviar" type="submit" onClick={hideForm} />
      </S.CustomForm>

      <IframeForm />
    </S.Container>
  )
}
