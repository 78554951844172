import { BusinessTheme } from '../../layout/theme'
import { ThemeColorsKeys } from './themeColorKeys'

type FacebookIconProps = {
  color?: ThemeColorsKeys
}

export function FacebookIcon({ color = 'blue700' }: FacebookIconProps) {
  return (
    <svg
      width="17"
      height="25"
      viewBox="0 0 17 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9.93467V15.0402H4.82914V23.9749H9.93467V15.0402H13.7638L15.0402 9.93467H9.93467V7.38191C9.93467 7.04339 10.0691 6.71874 10.3085 6.47937C10.5479 6.24 10.8725 6.10552 11.2111 6.10552H15.0402V1H11.2111C9.51846 1 7.8952 1.67238 6.69836 2.86922C5.50152 4.06606 4.82914 5.68932 4.82914 7.38191V9.93467H1Z"
        stroke={BusinessTheme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
