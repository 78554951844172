import { cloneElement } from 'react'
import styled, { css } from 'styled-components'

type ButtonProps = {
  width?: string
}

export const Button = styled.a<ButtonProps>`
  ${({ theme, width }) => css`
    align-items: center;
    border: 1px solid ${theme.colors.noverde};
    border-radius: 25px;
    background-color: ${theme.colors.noverde};
    color: ${theme.colors.white};
    display: flex;
    font-weight: theme.font-weight[400];
    justify-content: center;
    letter-spacing: 0.16px;
    min-height: 52px;
    padding: 5px 24px;
    position: relative;
    font-size: 1rem;
    width: ${width ? width : '100%'};
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background: ${theme.colors.white};
      color: ${theme.colors.noverde};
      box-shadow: 0 15px 35px #0cd15b26;
    }
  `}
`

export const Icon = styled(({ component, ...props }) =>
  cloneElement(component, props)
)`
  ${() => css`
    margin-top: 2px;
  `}
`
