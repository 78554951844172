import styled, { css } from 'styled-components'

export const HeaderMenuTitle = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    cursor: pointer;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    text-decoration: none;

    &:hover {
      color: ${theme.colors.noverde};
      transition: all 0.3s;
    }
  `}
`
