import { SelectHTMLAttributes } from 'react'
import * as S from './styles'

export type SelectFieldProps = {
  height?: string
  width?: string
  useBackground?: boolean
} & SelectHTMLAttributes<HTMLSelectElement>

const data = [
  {
    value: 'Qual produto deseja contratar ?',
    id: 'Qual produto deseja contratar ?'
  },
  {
    value: 'Empréstimo Pessoal - Indique e ganhe',
    id: 'Empréstimo Pessoal - Indique e ganhe'
  },
  {
    value: 'Empréstimo Pessoal na sua plataforma',
    id: 'Empréstimo Pessoal na sua plataforma'
  },
  {
    value: 'Financiamento para seus clientes',
    id: 'Financiamento para seus clientes'
  },
  {
    value: 'Outros',
    id: 'Outros'
  }
]

export function SelectField({ ...props }: SelectFieldProps) {
  return (
    <S.Select name="entry.1406579435" {...props}>
      {data.map((option, index) => (
        <option
          disabled={index === 0}
          selected={index === 0}
          hidden={index === 0}
          key={option.id}
        >
          {option.value}
        </option>
      ))}
    </S.Select>
  )
}
