import { ButtonLink } from 'components/buttonLink'
import { Wrapper } from 'components/wrapper'
import SimulationImage from '../../assets/images/simulation.webp'
import * as S from './styles'

export function SectionOne() {
  return (
    <Wrapper>
      <S.BackGround id="#">
        <S.Container>
          <S.WrapperInfos data-anime="left">
            <S.Chip> Soluções de crédito B2B2C</S.Chip>
            <S.Title>
              Finaciamento <strong>rápido</strong> para
              <strong> seus clientes</strong>
            </S.Title>

            <S.Body>
              Aumente suas vendas com novas opções de pagamento para seu
              cliente. Personalizado e do seu jeito!
            </S.Body>
            <S.WrapperButton>
              <ButtonLink
                title="Portal do Desenvolvedor"
                link="https://developers.noverde.com.br/reference"
                width="auto"
              />

              <ButtonLink
                title="Portal de financiamentos"
                link="https://portal.noverde.com.br"
                width="auto"
              />
            </S.WrapperButton>
          </S.WrapperInfos>

          <S.Logo
            src={SimulationImage}
            alt="simulation"
            data-anime="right"
          ></S.Logo>
        </S.Container>
      </S.BackGround>
    </Wrapper>
  )
}
