import { ButtonFab } from 'components/buttonFab'
import { Footer } from 'components/footer'
import { Header } from 'components/header'
import { SectionFour } from 'components/sectionFour'
import { SectionOne } from 'components/sectionOne'
import { SectionThree } from 'components/sectionThree'
import { SectionTwo } from 'components/sectionTwo'
import { SideMenu } from 'components/sideMenu'
import { useAnimation } from 'hooks/useAnimation'
import { useState } from 'react'
import { Waypoint } from 'react-waypoint'

export function Home() {
  const [openMenu, setMenu] = useState(false)
  const [showFab, setShowFab] = useState(false)

  const toggleMenu = () => {
    const removeOverFlowBody = document.querySelector('body') || null

    if (!openMenu && removeOverFlowBody) {
      removeOverFlowBody.style.overflowY = 'hidden'
    } else if (removeOverFlowBody) {
      removeOverFlowBody.style.overflowY = 'auto'
    }

    setMenu(!openMenu)
  }

  useAnimation()

  return (
    <>
      <Header toggleMenu={toggleMenu} />
      <SectionOne />
      <Waypoint onEnter={() => setShowFab(false)} />
      <SectionTwo />
      <Waypoint onEnter={() => setShowFab(true)}></Waypoint>
      <SectionThree />
      <SectionFour />
      <Footer />
      <SideMenu openMenu={openMenu} toggleSideMenu={toggleMenu} />
      <ButtonFab showFab={showFab} />
    </>
  )
}
