import { cloneElement, ReactNode } from 'react'
import { BusinessThemeProvider } from './theme'

interface AppProviderProps {
  children: ReactNode
}

// eslint-disable-next-line react/jsx-key
const contexts = [<BusinessThemeProvider />]

export const AppProvider = ({
  children: app
}: AppProviderProps): JSX.Element => (
  <>
    {contexts.reduce(
      (children, parent) => cloneElement(parent, { children }),
      app
    )}
  </>
)
