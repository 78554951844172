import { cloneElement } from 'react'
import styled, { css } from 'styled-components'
import { devices } from '../../layout/devices'

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
  `}
`
export const Header = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 700px;
  margin: 10px auto 20px;
  align-items: center;

  @media ${devices.mobileMedium} {
    flex-direction: column;
    gap: 40px;
  }
`

export const Divider = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.border};
    padding: 1rem;

    @media ${devices.medium} {
      margin: 190px auto 0px;
      position: sticky;
      top: 80px;
      background-color: ${theme.colors.white};
      padding: 1rem;
      transition: 0.5s;
      z-index: 5;
    }

    @media ${devices.xlarge} {
      margin: 290px auto 0px;
      position: sticky;
      top: 80px;
      background-color: ${theme.colors.white};
      padding: 1rem;
      transition: 0.5s;
      z-index: 5;
    }
  `}
`

export const Logo = styled.img`
  height: 100%;
  width: 100%;
`

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.noverde};
    font-size: 1rem;
  `}
`
export const TextOne = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: 2rem;
    font-weight: ${theme.font.weight[800]};
    text-align: left;
    line-height: 1.3;

    @media ${devices.mobile} {
      margin-top: 20px;
    }
  `}
`
export const TextTwo = styled.h6`
  ${({ theme }) => css`
    color: ${theme.colors.textblue};
    font-size: 1.25rem;
    font-weight: ${theme.font.weight[400]};
    text-align: left;
    line-height: 1.5;
  `}
`

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 600px;

  @media ${devices.medium} {
    margin-top: 100px;
  }
`

type GridProps = {
  direction?: string
}

export const Grid = styled.div<GridProps>`
  ${({ direction }) => css`
    display: flex;
    justify-content: center;
    flex-direction: ${!!direction && direction};
    gap: 40px;
    padding-top: 60px;

    @media ${devices.mobile} {
      flex-wrap: wrap;
      padding: 0;
    }
  `}
`

type HeaderItemProps = {
  select?: boolean
}

export const HeaderItem = styled.a<HeaderItemProps>`
  ${({ theme, select }) => css`
    color: ${theme.colors.black};
    cursor: pointer;
    text-decoration: none;
    position: relative;

    &:hover {
      color: ${theme.colors.noverde};
    }

    @media ${devices.large} {
      ${select &&
      `
      &:after {
        display: block;
        content: '';
        position: absolute;
        text-decoration: revert;
        background: ${theme.colors.noverde};
        top: 58px;
        left: 0;
        width: 100%;
        height: 2px;
        z-index: 0;
      }
      `}
    }

    ${select && `color: ${theme.colors.noverde}`}
  `}
`

export const Icon = styled(({ component, ...props }) =>
  cloneElement(component, props)
)`
  ${({ theme }) => css`
    color: ${theme.colors.iconbullets};
    @media ${devices.mobileMedium} {
      width: 1rem;
      height: 1rem;
    }
  `}
`

export const List = styled.div`
  display: -webkit-inline-box;
  align-items: baseline;
`

export const ListItem = styled.p`
  ${({ theme }) => css`
    font-size: 1.2rem;
    color: ${theme.colors.textgray};
    margin-left: 8px;
    max-width: 400px;
  `}
`
export const WrapperList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
