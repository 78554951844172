import React from 'react'
import * as S from './styles'

type ButtonProps = {
  size?: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export function ButtonForm({ title, ...rest }: ButtonProps) {
  return <S.Button {...rest}>{title}</S.Button>
}
