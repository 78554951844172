import styled, { css } from 'styled-components'
import { devices } from '../../layout/devices'

export const Header = styled.header`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: fit-content;
    padding: 1rem;
    z-index: 3;
    max-width: 1360px;
    margin: 0 auto;

    @media ${devices.medium} {
      padding: 1.5rem 4rem;
    }
  `}
`

interface HeaderMenuProps {
  open?: boolean
}

export const HeaderMenu = styled.ul<HeaderMenuProps>`
  ${({ open }) => css`
    align-items: center;
    display: flex;
    gap: 1rem;
    list-style: none;

    ${!!open &&
    `  
    transition: height 500ms;
    flex-direction: column;
    z-index: 1;
    align-items: end;
    align-items: end;
    padding: 0.5rem;
      `}
  `}
`
export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: fixed;
    z-index: 3;
    width: 100%;
    background: ${theme.colors.white};
  `}
`
