import * as S from './styles'

type HeaderTitleProps = {
  title: string
  link?: string
}

export function HeaderMenuTitle({ link, title, ...rest }: HeaderTitleProps) {
  return (
    <S.HeaderMenuTitle href={link} {...rest}>
      {title}
    </S.HeaderMenuTitle>
  )
}
