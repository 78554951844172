import styled, { css } from 'styled-components'

type Props = {
  open: boolean
}

export const Background = styled.div<Props>`
  ${({ open }) => css`
    display: flex;
    align-items: center;
    justify-content: start;
    height: 100%;
    position: fixed;
    width: ${open ? '100%' : '0'};
    top: ${open && '0'};
    z-index: ${open ? '10' : '-1'};
  `}
`

export const MenuContainer = styled.nav<Props>`
  ${({ open, theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: ${theme.colors.white};
    height: 100%;
    text-align: left;
    padding: 0 2rem;
    position: relative;
    top: 0;
    left: 0;
    transform: ${open ? 'translateX(0%)' : 'translateX(100%)'};
    transition: transform 0.5s ease-in-out;
    width: ${open ? '50rem' : '0%'};
    overflow: ${open ? 'auto' : 'hidden'};
    z-index: 1;
  `}
`

export const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
`

export const MenuItems = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;

  a {
    text-decoration: none;
  }
`

export const Overlay = styled.div<Props>`
  ${({ open }) => css`
    height: 100%;
    background: ${open ? '#00000025' : 'transparent'};
    transition: background ${open ? '0.5s ease-in-out' : '0.5 ease-out'};
    width: ${open ? '100%' : '0'};
    top: 0;
    left: 0;
    z-index: 1;
  `}
`
