import useWindowDimensions from '../../hooks/useDimensions'
import { Form } from './form'
import * as S from './styles'

export type SideMenuProfileProps = {
  openMenu: boolean
  toggleSideMenu: () => void
}

export const SideMenu = ({
  openMenu,
  toggleSideMenu
}: SideMenuProfileProps) => {
  const { hidesm } = useWindowDimensions()

  return (
    <S.Background open={openMenu}>
      {hidesm && <S.Overlay open={openMenu} onClick={toggleSideMenu} />}
      <S.MenuContainer open={openMenu}>
        <S.MenuContent>
          <S.MenuItems>
            <Form toggleSideMenu={toggleSideMenu} />
          </S.MenuItems>
        </S.MenuContent>
      </S.MenuContainer>
    </S.Background>
  )
}
