import React from 'react'
import * as S from './styles'

type ButtonProps = {
  title: string
  open?: boolean
  isFull?: boolean
  size?: string
  link?: string
  onClick?: () => void
}

export function Button({ link, title, ...rest }: ButtonProps) {
  return (
    <S.Button href={link} {...rest}>
      {title}
    </S.Button>
  )
}
