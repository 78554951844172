export function IframeForm() {
  return (
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSfSbP5D8m2qPy1u8btQtvJghcNV7Whh4Sp4v5-iUjaEm2mJ7g/viewform?embedded=true"
      frameBorder="0"
      marginHeight={0}
      marginWidth={0}
      style={{ visibility: 'hidden', height: '1px' }}
      id="my-response-iframe"
      name="my-response-iframe"
    >
      Enviar
    </iframe>
  )
}
