import { BiHome, BiLayer, BiMailSend, BiMoney } from 'react-icons/bi'
import { VscFilePdf } from 'react-icons/vsc'
import Pdf from '../../assets/pdf/noverde.pdf'
import * as S from './styles'

export function HowItWorks() {
  return (
    <S.Grid>
      <S.Container>
        <S.Title data-anime="top">
          <strong> Como funciona </strong>
          <p>a NoVerde Business?</p>
        </S.Title>

        <S.WrapperIcon data-anime="top">
          <S.Icon component={<BiMailSend />}></S.Icon>

          <S.SubTitle> Cadastre sua empresa </S.SubTitle>
        </S.WrapperIcon>

        <S.WrapperIcon data-anime="top">
          <S.Icon component={<BiLayer />}></S.Icon>

          <S.SubTitle>
            Entenda a melhor solução de crédito para seu negócio{' '}
          </S.SubTitle>
        </S.WrapperIcon>
        <S.WrapperIcon data-anime="top">
          <S.Icon component={<BiHome />}></S.Icon>

          <S.SubTitle>
            Integre seu checkout, estabelecimento ou wallet
          </S.SubTitle>
        </S.WrapperIcon>

        <S.WrapperIcon data-anime="top">
          <S.Icon component={<BiMoney />}></S.Icon>
          <S.SubTitle>
            Ofereça mais opções de crédito e cresça seu negócio
          </S.SubTitle>
        </S.WrapperIcon>
      </S.Container>

      <S.Image>
        <S.ContainerIcon href={Pdf} target="_blank" rel="noreferrer">
          <S.PdfIcon component={<VscFilePdf size={70} />}></S.PdfIcon>
        </S.ContainerIcon>
      </S.Image>
    </S.Grid>
  )
}
