import React from 'react'
import { BiMoney, BiSkipNextCircle } from 'react-icons/bi'
import { MdBugReport, MdSchool } from 'react-icons/md'
import * as Card from '../card/styles'
import { PartnerCard } from './partnerCard'
import * as S from './styles'

export function WrapperPartnerCard() {
  return (
    <S.CardWrapper>
      <PartnerCard>
        <Card.Card width="290px" height="310px">
          <Card.Icon component={<MdSchool />}></Card.Icon>

          <Card.Title>Jornada</Card.Title>

          <Card.Body>
            Proporcione uma experiência única para seu cliente
          </Card.Body>
        </Card.Card>
      </PartnerCard>

      <PartnerCard>
        <Card.Card width="290px" height="310px">
          <Card.Icon component={<MdBugReport />}></Card.Icon>

          <Card.Title>Motor Anti-fraude</Card.Title>

          <Card.Body>Mais segurança com quem entende do assunto</Card.Body>
        </Card.Card>
      </PartnerCard>

      <PartnerCard>
        <Card.Card width="290px" height="310px">
          <Card.Icon component={<BiMoney />}></Card.Icon>

          <Card.Title>Receita</Card.Title>

          <Card.Body>
            Aumente o faturamento com mais serviços financeiros
          </Card.Body>
        </Card.Card>
      </PartnerCard>

      <PartnerCard>
        <Card.Card width="290px" height="310px">
          <Card.Icon component={<BiSkipNextCircle />}></Card.Icon>

          <Card.Title>Plug-and-play</Card.Title>

          <Card.Body>
            Guias de integração, documentações para facilitar a sua vida
          </Card.Body>
        </Card.Card>
      </PartnerCard>
    </S.CardWrapper>
  )
}
