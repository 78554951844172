import styled from 'styled-components'
import { devices } from '../../layout/devices'

export const Container = styled.div`
  padding: 1rem;
  margin: 0 auto;
  max-width: 1360px;

  @media ${devices.large} {
    padding: 1.5rem 4rem;
  }
`
