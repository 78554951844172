import { BusinessTheme } from '../../layout/theme'
import { ThemeColorsKeys } from './themeColorKeys'

type LinkedinIconProps = {
  color?: ThemeColorsKeys
}

export function LinkedinIcon({ color = 'blue700' }: LinkedinIconProps) {
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3922 1H4.82319C3.206 1 1.89502 2.31099 1.89502 3.92817V21.4972C1.89502 23.1144 3.206 24.4253 4.82319 24.4253H22.3922C24.0094 24.4253 25.3204 23.1144 25.3204 21.4972V3.92817C25.3204 2.31099 24.0094 1 22.3922 1Z"
        stroke={BusinessTheme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.75146 11.249V18.5694"
        stroke={BusinessTheme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.75146 6.85645V6.87221"
        stroke={BusinessTheme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6079 18.5694V11.249"
        stroke={BusinessTheme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4642 18.5694V14.1772C19.4642 13.4006 19.1557 12.6558 18.6066 12.1067C18.0575 11.5575 17.3127 11.249 16.5361 11.249C15.7595 11.249 15.0147 11.5575 14.4656 12.1067C13.9164 12.6558 13.6079 13.4006 13.6079 14.1772"
        stroke={BusinessTheme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
