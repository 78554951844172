import { devices } from 'layout/devices'
import styled, { css } from 'styled-components'

export const Root = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    height: fit-content;
    position: relative;
    width: 100%;

    @media ${devices.mobileMedium} {
      padding: 1rem;
    }
  `}
`

export const Head = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.noverde}30;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    width: 100%;

    @media ${devices.small} {
      flex-direction: row;
    }
  `}
`

export const HeadMenu = styled.ul`
  ${({ theme }) => css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: flex-start;
    list-style: none;
    padding: 2rem 0;
    width: 100%;

    @media ${devices.small} {
      align-items: center;
      flex-direction: row;
    }

    @media ${devices.medium} {
      flex-wrap: nowrap;
    }

    a {
      color: ${theme.colors.blue700};
      cursor: pointer;
      font-size: 1rem;
      font-weight: 400;
      text-decoration: none;
    }
  `}
`

export const HeadIcons = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  padding: 1rem 0;

  @media ${devices.small} {
    align-items: center;
    justify-content: flex-end;
    padding: 0;
  }
`

export const Body = styled.div`
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 4rem;
  height: fit-content;
  justify-content: space-between;
  padding: 4rem 0;
  width: 100%;

  @media ${devices.small} {
    flex-direction: row;
  }

  @media ${devices.medium} {
    flex-wrap: nowrap;
  }
`

export const BodyContentLeft = styled.div`
  ${({ theme }) => css`
    align-items: baseline;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    max-width: 20rem;
    width: 100%;

    p {
      color: ${theme.colors.blue700};
      font-size: 1.2rem;
      font-weight: 800;
      margin-bottom: 1rem;
    }

    span {
      color: ${theme.colors.text};
      font-size: 1rem;
      font-weight: 400;
    }
  `}
`

export const BodyContentRight = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: fit-content;
    justify-content: flex-end;
    width: 100%;

    @media ${devices.small} {
      flex-direction: row;
    }
  `}
`

export const BodyContentRightMenu = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: fit-content;
    list-style: none;
    width: 100%;

    a {
      text-decoration: none;
      color: ${theme.colors.blue700};
      cursor: pointer;
      font-size: 1rem;
      font-weight: 400;

      &:hover {
        color: ${theme.colors.noverde};
      }
    }

    label {
      color: ${theme.colors.blue700};
      font-size: 1.2rem;
      font-weight: 800;
    }
    p {
      color: ${theme.colors.text};
      font-size: 1.1rem;
      font-weight: 400;
      word-wrap: break-word;
    }
  `}
`
