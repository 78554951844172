import _ from 'lodash'
import { useEffect } from 'react'

export function useAnimation() {
  // eslint-disable-next-line prefer-const
  let targets = document.querySelectorAll('[data-anime]')
  const animationClass = 'animate'

  const animeScroll = () => {
    const windowTop = window.pageYOffset + (window.innerHeight * 3) / 4
    targets.forEach(element => {
      const transformElement = element as HTMLElement

      if (windowTop > transformElement.offsetTop) {
        element.classList.add(animationClass)
      }
    })
  }

  useEffect(() => {
    // eslint-disable-next-line prettier/prettier
    // eslint-disable-next-line react-hooks/exhaustive-deps
    targets = document.querySelectorAll('[data-anime]')
    animeScroll()
  }, [])

  if (targets) {
    window.addEventListener(
      'scroll',
      _.debounce(() => animeScroll(), 200)
    )
  }
}
