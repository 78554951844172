type inputTypes = {
  inputs: string[]
}

export const validateForm = ({ inputs }: inputTypes) => {
  let isNotEmptyField = true

  inputs.forEach(input => {
    const currentInput = (document.getElementById(input) as HTMLInputElement)
      .value

    if (!currentInput) {
      isNotEmptyField = false
    }
  })

  return isNotEmptyField
}
