import { Home } from 'components/home'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { routesName } from './routes-name'

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={routesName.home} component={Home} />
        <Redirect to={routesName.home} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
