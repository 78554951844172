import { createGlobalStyle } from 'styled-components'

const ResetCSS = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
  }
  html, body {
    height: 100%;
    background: #fff;
    scroll-behavior: smooth;
  }
  body {
    font-size: 1rem;
    line-height: 1.5;
    position: relative;
    overflow-x: clip;
  }

  ::-webkit-scrollbar {
    height: 12px;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 12px;
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #0cd15b;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  [data-anime] {
    opacity: 0;
    transition: .3s;
  }

  [data-anime="left"] {
    transform: translate3d(-15px, 0, 0);
  }

  [data-anime="right"] {
    transform: translate3d(15px, 0, 0); 
  }

  [data-anime="top"] {
    transform: translate3d(0, -50px, 0);
  }

  [data-anime="down"] {
    transform: translate3d(0, 50px, 0);
  }

  [data-anime].animate {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
`
export default ResetCSS
