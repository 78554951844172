import styled, { css } from 'styled-components'
import { devices } from '../../layout/devices'

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.graybackground};
  `}
`

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: 2.5rem;
    font-weight: ${theme.font.weight[800]};
    text-align: center;
    line-height: 1.3;

    @media ${devices.mobileMedium} {
      font-size: 2rem;
    }

    & > strong {
      color: ${theme.colors.noverde};
      font-size: 2.5rem;
      font-weight: ${theme.font.weight[800]};
      text-align: center;
      line-height: 1.3;
    }
  `}
`
interface SubTitleProps {
  margin?: string
}

export const SubTitle = styled.p<SubTitleProps>`
  ${({ theme, margin }) => css`
    font-size: 1.25rem;
    text-align: center;
    color: ${theme.colors.textblue};
    font-weight: ${theme.font.weight[400]};
    max-width: 800px;
    text-align: center;
    margin: ${margin ? `${margin}` : '1rem auto'};
  `}
`
export const TextOne = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: 2rem;
    font-weight: ${theme.font.weight[800]};
    text-align: left;
    line-height: 1.3;

    @media ${devices.medium} {
      font-size: 3rem;
    }
  `}
`
export const TextTwo = styled.h6`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: 1rem;
    font-weight: ${theme.font.weight[400]};
    text-align: left;
    line-height: 1.5;

    @media ${devices.medium} {
      font-size: 1.25rem;
    }
  `}
`

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 600px;

  @media ${devices.medium} {
    margin-top: 100px;
  }
`

export const Grid = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;

  @media ${devices.mobile} {
    flex-wrap: wrap;
  }
`
export const WrapperDivider = styled.div`
  position: relative;
`

export const ComplementDivider = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 200px;
    background: ${theme.colors.white};
  `}
`
export const ContainerDivider = styled.div`
  position: absolute;
  width: 100%;
  position: absolute;
  z-index: 1;
  bottom: -200px;
  margin-bottom: 100px;
`

export const WrapperDots = styled.div`
  position: relative;
`
