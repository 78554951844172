import ResetCSS from 'layout/reset'
import { AppProvider } from 'providers/app'
import Routes from 'routes'

function App() {
  return (
    <AppProvider>
      <ResetCSS />
      <Routes />
    </AppProvider>
  )
}

export default App
