import styled, { css } from 'styled-components'
import { devices } from '../../layout/devices'

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.graybackground};
    margin-top: 100px;
  `}
`

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: 2.5rem;
    font-weight: ${theme.font.weight[800]};
    text-align: center;
    line-height: 1.3;
    margin-top: 50px;

    @media ${devices.mobileMedium} {
      font-size: 2rem;
    }

    & > strong {
      color: ${theme.colors.noverde};
    }
  `}
`

export const SubTitle = styled.p`
  ${({ theme }) => css`
    font-size: 1.25rem;
    text-align: center;
    color: ${theme.colors.textblue};
    font-weight: ${theme.font.weight[400]};
    max-width: 800px;
    text-align: center;
    margin: 20px auto 100px;
  `}
`
export const Grid = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;

  @media ${devices.mobile} {
    flex-wrap: wrap;
  }
`

export const ContainerCollapse = styled.div`
  ${({ theme }) => css`
    max-width: 600px;
    padding: 25px 35px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0 15px 35px ${theme.colors.greyborders};
    border: 1px solid ${theme.colors.borderCollapse};
  `}
`

export const CollapseTitle = styled.p`
  ${({ theme }) => css`
    font-size: 1rem;
    color: ${theme.colors.black};
    width: 100%;

    :hover {
      color: ${theme.colors.noverde};
    }
  `}
`
export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

export const Infos = styled.p`
  ${({ theme }) => css`
    font-size: 1rem;
    color: ${theme.colors.textgray};
    font-weight: ${theme.font.weight[400]};
    padding-top: 24px;
  `}
`
export const GridCollapse = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const WrapperContainerText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > strong {
    font-size: 1rem;
  }
`

export const ContainerForm = styled.div`
  max-width: 600px;
  padding: 3rem 4rem;
  border-radius: 10px;
  background-color: white;
  height: fit-content;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 70px rgb(38 42 76 / 20%);
  display: flex;
  gap: 20px;

  @media ${devices.mobileMedium} {
    padding: 1rem 2rem;
  }
`

export const FormTitle = styled.h1`
  ${({ theme }) => css`
    font-size: 1.4rem;
    text-align: center;
    color: ${theme.colors.black};
    font-weight: ${theme.font.weight[800]};
  `}
`

export const FormSubtitle = styled.p`
  ${({ theme }) => css`
    font-size: 1rem;
    text-align: center;
    color: ${theme.colors.textblue};
    max-width: 450px;
    margin-bottom: 20px;
  `}
`
export const CustomForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
`

export const Success = styled.p`
  ${({ theme }) => css`
    font-size: 1.25rem;
    text-align: center;
    color: ${theme.colors.noverde};
    font-weight: ${theme.font.weight[400]};
    text-align: center;
  `}
`
export const Link = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.noverde};
  `}
`
