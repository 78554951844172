import { Divider } from 'components/divider'
import { Dots } from 'components/dots'
import { HowItWorks } from 'components/howItWorks'
import { WrapperPartnerCard } from 'components/partnerCard'
import { SectionFive } from 'components/sectionFive'
import { Wrapper } from 'components/wrapper'
import useWindowDimensions from '../../hooks/useDimensions'
import * as S from './styles'

export function SectionFour() {
  const { hidelg } = useWindowDimensions()

  return (
    <S.Container id="Parceiro">
      <Divider />

      <Wrapper>
        <S.Title>
          Por que virar um <strong>parceiro</strong> Noverde?
        </S.Title>

        <S.SubTitle data-anime="down">
          Por meio da nossa infraestrutura de crédito e cobrança já ajudamos +
          200 mil pessoas, totalizando um valor emprestado de + R$300 milhões de
          reais.
        </S.SubTitle>
        <S.WrapperDots data-anima="down">
          {hidelg && (
            <Dots
              columns={10}
              rows={10}
              useTop="-10px"
              useRight="-40px"
              data-anime="right"
            />
          )}

          <S.Grid>
            <WrapperPartnerCard />
          </S.Grid>
          {hidelg && (
            <Dots
              columns={14}
              rows={10}
              useTop="130px"
              useLeft="-40px"
              data-anime="left"
            />
          )}
        </S.WrapperDots>

        <S.SubTitle margin="80px auto 40px">
          Tudo isso em uma infraestrutura escalável, robusta e com segurança de
          dados.
        </S.SubTitle>
      </Wrapper>

      <S.WrapperDivider>
        <HowItWorks />

        <S.ContainerDivider>
          <S.ComplementDivider id="SaibaMais" />
          <Divider />
        </S.ContainerDivider>
      </S.WrapperDivider>

      <SectionFive />
    </S.Container>
  )
}
