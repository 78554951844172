import { ButtonLink } from 'components/buttonLink'
import { Card } from 'components/card'
import { Divider } from 'components/divider'
import { Wrapper } from 'components/wrapper'
import * as S from './styles'

export function SectionTwo() {
  return (
    <S.Container id="SolucoesDeCredito">
      <Divider />
      <Wrapper>
        <S.Title>
          Solução de financiamento na <strong>jornada de compra</strong>
        </S.Title>
        <S.SubTitle>Crédito customizado e conectado ao seu negócio</S.SubTitle>

        <S.Grid>
          <Card />

          <S.ContainerText>
            <S.TextOne>
              Aumente sua receita com empréstimos e linhas de financiamento para
              seus clientes pessoa física
            </S.TextOne>

            <S.TextTwo>
              Experiências memoráveis, produtos de crédito inteligentes,
              customizados e integrados na jornada do seu cliente. Tudo isso,
              numa experiência digital: poucos cliques e mais vendas.
            </S.TextTwo>

            <ButtonLink
              title="Saiba mais"
              width="200px"
              link="#SaibaMais"
              data-anime
            />
          </S.ContainerText>
        </S.Grid>
      </Wrapper>
    </S.Container>
  )
}
