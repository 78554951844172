import { cloneElement } from 'react'
import styled, { css } from 'styled-components'
import { devices } from '../../layout/devices'

interface CardProps {
  width?: string
  height?: string
}

export const Card = styled.div<CardProps>`
  ${({ theme, width, height }) => css`
    align-items: center;
    background-color: ${theme.colors.white};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    position: relative;
    width: ${width ? width : '280px'};
    height: ${height ? width : '330px'};
    margin-top: 20px;
    box-shadow: 0 15px 35px ${theme.colors.greyborders};
    transition: ${theme.transition.default};

    ${!width &&
    `
    &:hover {
      box-shadow: 0 5px 70px ${theme.colors.boxshadow};
      border: none;
    }
    `}
  `}
`
export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: 1.25rem;
    font-weight: $theme.font[600];
    text-align: center;
    margin: 16px 0 8px;
  `}
`

export const Body = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.textgray};
    font-size: 1rem;
    font-weight: ${theme.font.weight[600]};
    text-align: center;
    margin-top: 0.5rem;
  `}
`

export const Icon = styled(({ component, ...props }) =>
  cloneElement(component, props)
)`
  ${({ theme }) => css`
    color: ${theme.colors.iconcard};
    height: 3rem;
    width: 3rem;
    margin-top: 2rem;
  `}
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
  grid-gap: 20px;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.medium} {
    &.down {
      margin-top: 100px;
      padding: 1rem;
    }
  }
`
export const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;

  @media ${devices.small || devices.xsmall} {
    padding: 1rem;
  }

  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
`
