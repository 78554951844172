import { ReactNode, useState } from 'react'
import { Collapse } from 'react-collapse'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import * as S from './styles'

interface CustomCollapseCardProps {
  title: string
  infos: ReactNode
}

export function CustomCollapseCard({ title, infos }: CustomCollapseCardProps) {
  const [openMenu, setOpenMenu] = useState(false)

  return (
    <S.ContainerCollapse data-anime="down">
      <S.WrapperIcon onClick={() => setOpenMenu(!openMenu)}>
        <S.CollapseTitle>{title}</S.CollapseTitle>
        {openMenu ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </S.WrapperIcon>
      <Collapse
        theme={{ collapse: 'CollapseCard' }}
        isOpened={openMenu}
        initialStyle={{ height: 0, overflow: 'hidden' }}
      >
        <S.Infos>{infos}</S.Infos>
      </Collapse>
    </S.ContainerCollapse>
  )
}
