import styled, { css } from 'styled-components'
import { devices } from '../../layout/devices'

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.graybackground};

    @media ${devices.large} {
      max-height: 1600px;
    }

    @media ${devices.xlarge} {
      max-height: 900px;
    }
  `}
`

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: 2.5rem;
    font-weight: ${theme.font.weight[800]};
    text-align: center;
    line-height: 1.3;

    @media ${devices.medium} {
      margin-top: 50px;
    }

    @media ${devices.mobileMedium} {
      font-size: 2rem;
    }

    & > strong {
      color: ${theme.colors.noverde};
      font-size: 2.5rem;
      font-weight: ${theme.font.weight[800]};
    }
  `}
`

export const SubTitle = styled.p`
  ${({ theme }) => css`
    font-size: 1.25rem;
    text-align: center;
    color: ${theme.colors.textblue};
    font-weight: ${theme.font.weight[400]};
    margin-top: 1rem;
  `}
`
export const TextOne = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: 2rem;
    font-weight: ${theme.font.weight[800]};
    text-align: left;
    line-height: 1.3;
  `}
`
export const TextTwo = styled.h6`
  ${({ theme }) => css`
    color: ${theme.colors.textblue};
    font-size: 1rem;
    font-weight: ${theme.font.weight[400]};
    text-align: left;
    line-height: 1.5;

    @media ${devices.medium} {
      font-size: 1.25rem;
    }
  `}
`

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 600px;

  @media ${devices.medium} {
    margin-top: 200px;
  }
`

export const Grid = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;

  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }

  @media ${devices.medium} {
    gap: 60px;
  }
`
