import { InputHTMLAttributes, useRef } from 'react'
import * as S from './styles'

export type TextFieldProps = {
  height?: string
  width?: string
  useBackground?: boolean
} & InputHTMLAttributes<HTMLInputElement>

export function TextField({ ...props }: TextFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null)

  const setCustomValidity = (customMsg?: string) => {
    inputRef.current?.setCustomValidity(
      typeof customMsg === 'string' ? '' : 'Preencha este campo'
    )
  }

  return (
    <S.Input
      required
      ref={inputRef}
      onInvalid={() => setCustomValidity()}
      onInput={() => setCustomValidity('')}
      {...props}
    />
  )
}
